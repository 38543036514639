import * as React from "react"

import Layout from "../../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>ご指定されたページは存在しません</p>
  </Layout>
)

export default NotFoundPage
